<template>
  <div id="app">
    <div class="headerTop">
      <!-- 顶部 -->
      <div class="appLogo">
      </div>
      <div class="appRightBlock">
        <div class="appTitle">
        </div>
        <div>
          <div id="downloadButtons">
            <!-- 按钮并排 -->
            <img @click="onClickApk()" v-if="is_apk" class="downloadButtonsImg" src="@/assets/Android-APK.png">
            <img @click="onClickPlayStore()" v-if="is_android" class="downloadButtonsImg"
              src="@/assets/Google-Play.png">
            <img @click="onClickAppStore()" v-if="is_ios" class="downloadButtonsImg" src="@/assets/App-Store.png">
          </div>
        </div>
      </div>
    </div>
    <div class="iconList">
      <!-- 图标 -->
      <img class="iconFeature" src="@/assets/Live.png">
      <img class="iconGap" src="@/assets/vline.png">
      <img class="iconFeature" src="@/assets/Game.png">
      <img class="iconGap" src="@/assets/vline.png">
      <img class="iconFeature" src="@/assets/Chat.png">
      <img class="iconGap" src="@/assets/vline.png">
      <img class="iconFeature" src="@/assets/social.png">
    </div>
    <div>
      <div class="lblPreview">
        Önizleme
      </div>
      <!-- slides -->
      <div class="dl-slider">
        <div class="slide-area">
          <div>
            <div class="slide-btn" v-for="(i,x) in list" :key="x">
              <img class="slide-image" :src="i.image">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- <summary></summary> -->
      <div class="summaryInfo">
        Özet
      </div>
      <div class="summaryList">
        <li>
          <span class="summaryInfoTitle"><img src="@/assets/circle.png">Canlı sohbet odası</span>
          <div class="summaryInfoItem">
            İstediğiniz zaman sesli olarak arkadaşlarınızla konuşabilirsiniz. Engeller olmadan arkadaşlarınızla bağlantı
            kurabilirsiniz.
          </div>
        </li>
        <li>
          <span class="summaryInfoTitle"><img src="@/assets/circle.png">Yeni arkadaşlarla tanışmak hiç bu kadar kolay
            olmaz</span>
          <div class="summaryInfoItem">
            Her gün yüzlerce çevrimiçi açık oda akışı! Etiketleri ile odaları filtreleyebilirsiniz. Orada yeni
            arkadaşlarla tanışın ve benzer ilgi alanlarına sahip arkadaşlarla takılın~
          </div>
        </li>
        <li>
          <span class="summaryInfoTitle"><img src="@/assets/circle.png">Arkadaşlarla parti yapmak</span>
          <div class="summaryInfoItem">
            Özel odalar, arkadaşlarınızla parti yapmanızı sağlar! Yada tema odanızı oluşturun. Filmler, Karaoke, aşk
            hikayeleri, makyaj, müzik, tema istediğiniz gibi. İlgi alanlarınıza göre oluşturun.
          </div>
        </li>
        <li>
          <span class="summaryInfoTitle"><img src="@/assets/circle.png">Çeşitli 101 oyun modu</span>
          <div class="summaryInfoItem">
            Çeşitli 101 oyun modu. Okey 101'i çevrimdışı oynadığınız şekilde oynayabilirsiniz.
          </div>
        </li>
        <li>
          <span class="summaryInfoTitle"><img src="@/assets/circle.png">Oyun partneriniz bulunmak</span>
          <div class="summaryInfoItem">
            Binlerce çevrimiçi oyuncu birlikte oynayabilirsiniz~ Oyun randevunuz olsun! Partnerinizi Sohbet&Okey 101'de
            bulun
          </div>
        </li>
        <li>
          <span class="summaryInfoTitle"><img src="@/assets/circle.png">Harika hediyeler</span>
          <div class="summaryInfoItem">
            Arkadaşlarınızı ne kadar sevdiğinizi göstermek için harika hediyeler gönderebilirsiniz. Hediyeler duvarında
            kupalarınızla övünebilirsiniz. Kimin daha popüler olduğunu görün!
          </div>
        </li>
      </div>
    </div>
    <!-- <div> -->
      <!-- <footer></footer> -->
      <!-- <div @click="onClickFollowus()">
        <img class="followUsButton" src="@/assets/Follow-Us.png">
      </div> -->
    <!-- </div> -->

  </div>
</template>

<script>
// import { Slider, SliderItem } from "vue-easy-slider";

export default {
  name: "App",
  // components: { Slider, SliderItem },
  data() {
    return {
      list: [
        {
          image: "images/Chat-while--Play-Pusoy.png",
        },
        {
          image: "images/Genuine--Philippine-game.png",
        },
        {
          image: "images/Live-chat-and--meet-new-friends.png",
        },
        {
          image: "images/Hop--in-to-play.png",
        },
      ],
      is_android: false,
      is_ios: false,
      is_pc: false,
      is_apk: true,
    };
  },
  methods: {
    onClickAppStore(){
      const appstoreUrl = "http://app.okey101u.com/101okey"
      window.open(appstoreUrl, "blank")
    } ,
    onClickPlayStore(){
      let appstoreUrl = "market://details?id=com.WeWorld.OkeyGel"
      // if(this.is_pc){
        appstoreUrl = "https://play.google.com/store/apps/details?id=com.WeWorld.OkeyGel"
      // }
      window.open(appstoreUrl, "blank")
    },
    onClickApk(){
      const appstoreUrl = "/release/101okey.apk"
      window.open(appstoreUrl, "blank")
    },
    onClickFollowus(){
      // const fbUrl = "https://www.facebook.com/WeCard-Live-Chat-Tongits-104931101993092/"
      // window.open(fbUrl, "blank")
    }
  },
  created() {
    let p = navigator.platform;
    let u = navigator.userAgent;
    this.is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
    this.is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    this.is_pc = false;
    this.is_apk = this.is_android;
    if (
      p == "Win32" ||
      p == "Win64" ||
      p == "MacPPC" ||
      p == "MacIntel" ||
      p == "X11" ||
      p == "Linux i686"
    ) {
      //PC调试环境
      this.is_pc = true;
      this.is_ios = true;
      this.is_android = true;
      this.is_apk = false;
    }
  },

};
</script>

<style>

  body {
    margin: 0px !important;
  }

  div {
    border: 0;
    margin: 0;
    padding: 0;
  }


  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 40px;
    user-select: none;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .headerTop{
    display:flex;
    flex-direction:row;
    padding-left: 36px ;
    padding-right: 36px ;
  }


  .appLogo{
    display: block;
    background-image: url("assets/logo.png");
    height: 214px;
    width: 214px;
    background-size: 667px 214px;
    background-repeat: no-repeat;
  }

  .appTitle{
    background-image: url("assets/logo.png");
    background-position: -250px 0px;
    height: 100px;
    width: 417px;
    background-size: 667px 214px;
    background-repeat: no-repeat;
  }

  .appRightBlock{
    margin-left: 36px;
    display:flex;
    flex-direction:column;
    width: 430px;
  }

  .iconList{
    display: flex;
    justify-content: center;
    margin-top: 36px;
    border-top:#c2c0c0 solid 4px;
    border-bottom: solid #c2c0c0 2px;
    margin-left:36px;
    margin-right:36px;
  }

  .iconFeature {
    width: 74px;
    height: 84px;
    padding: 36px 50px;
    background-color: #fff;
  }

  .iconGap {
    height: 52px;
    margin-top: 72px;
    width: 2px;
  }

   .lblPreview{
    font-size: 40px;
    font-weight: bold;
    text-align: left;
    margin: 36px;
  }

  .summaryInfo{
    margin-top: 48px ;
    margin-left: 36px;
    font-weight: bold;
    text-align: left;
    font-size: 40px;
  }

  .summaryInfoTitle{
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 600;
  }
  .summaryInfoTitle > img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }

  .summaryInfoItem{
    margin-top: 10px;
    margin-left: 30px;
    font-size: 28px;
    font-weight: 450;
  }


  .summaryList {
    text-align: left;
    font-size: 30px;
    font-weight: 500;
    padding:0px 36px;
    margin-bottom: 36px;
  }

  .summaryList li{
    margin-top: 36px;
    list-style-type: none;
  }



  .followUsButton{
    width: 678px;
    height: 106px;
    padding-top: 36px;
    border-top: #c2c0c0 solid 2px;
    margin-bottom: 500px;
  }


  #downloadButtons {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      justify-content: space-between;
  }

  .downloadButtonsImg {
      display: block;
      width: 211px;
      height: 71px;
  }

 
.dl-slider {
  width: 100%;
}

.slide-area{
  display: inline-block;
  /* height: 100%; */
  white-space: nowrap;
  overflow-x: auto;
  width: 753px;
}

.slide-btn{
  cursor: pointer;
  display: inline-block;
  background: none;
}

.slide-image{
  margin-left:18px;
  max-width:446px !important;
  max-height:964px !important;
}

.gallery {
  width: 753px;
  display: flex;
  float: left;
  overflow-x: scroll;
}

.gallery-image {
  margin-left: 18px;
  display:inline;
  width: 446px;
  height:964px;
}



</style>
